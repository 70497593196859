import './App.css';
//import Dashboard from './components/Dashboard/Dashboard';
import List from './components/List';
function App() {
  return (
    <div className="App">
      <List/>
    </div>
  );
}

export default App;
